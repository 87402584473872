import './Us.css';
import ornement from '../ornement.svg';

export default function Us({ isOpened, setIsOpened }) {

  return (
    <div className="us-content">
        <h2>Les mariés</h2>
        <img src={ornement} className="us-ornement" alt="logo"/>
        <p>
          <strong>Florine Claire</strong>, c’est une boule d’énergie qui illumine tout sur son passage. Elle a toujours mille idées à la minute, que ce soit dans la vie ou au boulot. Elle aime l’effervescence de la ville, les bons petits restos, les moments avec les gens qu’elle aime, et nos deux chats bien sûr ! Ce que j’aime chez elle, c’est son pragmatisme, son dynamisme, et cette façon qu’elle a de transformer chaque instant en quelque chose d’unique. Elle me pousse à sortir de ma zone de confort et je n’imagine définitivement pas ma vie sans elle.
          <br/><br/>
          <strong>Thibaut</strong>, c’est mon pilier. Toujours calme et posé, il trouve des solutions à tout avec une facilité déconcertante. C’est le genre de personne qui peut réparer une étagère un jour et passer des heures à faire notre site de mariage le lendemain. Il est curieux, intelligent, et il m’impressionne constamment avec sa capacité à rester zen en toute situation. Avec lui, je me sens apaisée et surtout prête à tout affronter, parce que je sais qu’il est là, toujours.
          <br/><br/>
          <strong>Ensemble</strong>, on est un mélange qui fonctionne : l’énergie débordante de Florine Claire et le calme réfléchi de Thibaut. On partage un amour pour les bons moments entre amis, les projets ambitieux (parfois un peu fous) et, surtout, pour les choses simples qui rendent la vie belle. Ce mariage, c’est notre façon de célébrer tout ça avec vous, les personnes qu’on aime le plus.
        </p>
        <h2>Nos témoins</h2>
        <img src={ornement} className="us-ornement" alt="logo"/>
        <p>
          <strong>Maud</strong>, c’est la sœur de Florine Claire, et autant dire qu’avec elle, il n’y a pas de faux-semblants. Elles se bousculent autant qu’elles se soutiennent, et surtout, elles sont là l’une pour l’autre, toujours.
          <br/><br/>
          <strong>Luc</strong>, c’est l’ami sur qui on peut toujours compter. Super fiable, généreux et présent quand il faut, et surtout, un vrai moteur d’ambiance. Son humour est indiscutable (et largement validé), et il y a toujours un bon moment à passer avec lui.
          <br/><br/>
          <strong>Manon</strong>, c’est la douceur et la bienveillance incarnées. Avec elle, on peut autant refaire le monde autour d’une tisane que partir sur une grosse soirée. Elle a des goûts musicaux parfois discutables, mais compense largement avec un déhanché infaillible.
          <br/><br/>
          <strong>Marin</strong>, c’est l'électron libre du groupe. Une énergie inépuisable et des décisions imprévisibles. Avec Thibaut, il a enchaîné les nuits de code, les débats interminables, les anectodes improbables et les parties de jeux de stratégie.
          <br/><br/>
          Pour l'anectode, c'est au mariage de <strong>Manon</strong> et <strong>Marin</strong> que nous nous sommes rencontrés.
        </p>
    </div>
  );
}

