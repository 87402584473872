import './AddMusic.css';
import TextField from '@mui/material/TextField';
import {useState} from "react";
import { Button, CircularProgress } from '@mui/material';
import MusicNoteIcon from '@mui/icons-material/MusicNoteOutlined';
import ornement from '../ornement.svg';

function AddMusic({ isOpened, setIsOpened }) {
    const [music, setMusic] = useState("");
    const [isOkLoading, setIsOkLoading] = useState(false);
    const [musicData, setMusicData] = useState(null);
    const [musicError, setMusicError] = useState(null);

    const addMusic = async (music) => {
        setMusicError(null);
        try {
            const res = await fetch(`https://66e3f2e684fa527d1e5c.appwrite.global/music`, {
                method: 'POST',
                body: JSON.stringify({
                    music,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!res.ok) {
                throw new Error(res.statusText);
            }
            setMusicError(null);
            setMusicData(await res.json());
            setMusic("");
        } catch (error) {
            console.error(error);
            setMusicError(error);
            return;
        }
    }

    const onClickOk = async () => {
        setIsOkLoading(true);
        await addMusic(music);
        setIsOkLoading(false);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && music) {
            onClickOk();
        }
    };

    return (
        <div className="add-music-content">
            <h2>Faites bouger la piste !</h2>
            <img src={ornement} className="add-music-ornement" alt="logo"/>
            <p>
                Proposez vos morceaux préférés pour enflammer la soirée.
                <br/><br/>
                Pour ajouter une musique, il vous suffit de taper son titre dans le champ ci-dessous, puis d’appuyer sur le bouton avec la note de musique <MusicNoteIcon />.
            </p>
            <br/>
            <div className='add-music-inputs'>
                <TextField
                    className='add-music-input'
                    label={"Musique"}
                    onChange={(e) => setMusic(e.target.value)}
                    value={music}
                    onKeyDown={handleKeyDown}
                />
                <Button
                    variant="contained"
                    onClick={onClickOk}
                    disabled={!music}
                >
                    {isOkLoading ? (
                        <CircularProgress size="2em" color='inherit'/>
                    ) : (
                        <MusicNoteIcon/>
                    )}
                </Button>
            </div>
            {musicError && <div>Error: {musicError.message}</div>}
            {musicData && <div>Musique ajoutée</div>}
            <h2>Faites nous sourire !</h2>
            <img src={ornement} className="add-music-ornement" alt="logo"/>
            <p>
                Envie de nous surprendre et de marquer le coup ?
                <br/>Que ce soit une animation, un discours ou une surprise inoubliable, nos témoins sont dans la confidence !
                <br/>Contactez-les à l’adresse ci-dessous pour organiser tout en secret… On a hâte de découvrir ce que vous nous réservez ! 
                <br/><br/>
                Adresse email : <a href="mailto:surprise.thifloc@gmail.com">surprise.thifloc@gmail.com</a>
            </p>
        </div>
    );
}

export default AddMusic;
