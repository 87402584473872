import './UsefulInformation.css';
import ornement from '../ornement.svg';
import map from '../france.png';
import { Dialog } from '@mui/material';
import { useState } from 'react';

function UsefulInformation({ isOpened, setIsOpened }) {
  const [isEasterEggHostingOpened, setIsEasterEggHostingOpened] = useState(false);

  return (
      <div className="useful-information-content">
        <h2>Lieux</h2>
        <img src={ornement} className="useful-information-ornement" alt="logo"/>
        <p>
          La cérémonie civile est indiquée à titre informatif : votre présence n'est pas obligatoire. Pour nous, l'essentiel est de partager avec vous la cérémonie laïque.
          <br/><br/>
          <strong>Cérémonie civile :</strong>
          <br/>Hôtel de ville de Strasbourg
          <br/>
          <a
            target="_blank"
            href="https://www.google.com/maps/search/48.584875,+7.750501?coh=219680&utm_campaign=tt-rcs&entry=tts&g_ep=EgoyMDI1MDEyMi4wIPu8ASoASAFQAw%3D%3D" rel="noreferrer"
          >9 rue Brulée, 67000 Strasbourg</a> (l'entrée se fait par la place Broglie)
          <br/><br/>
          <strong>Cérémonie laïque et réception :</strong>
          <br/>Moulin de la Mangue
          <br/>
          <a
              target="_blank"
              href="https://www.google.com/maps/place/Le+Moulin+de+la+Mangue/@47.558968,6.5758617,1111m/data=!3m1!1e3!4m10!3m9!1s0x479269f5c3bcf7f7:0x2a76f11ac0bf6545!5m3!1s2024-12-30!4m1!1i2!8m2!3d47.558968!4d6.578442!16s%2Fg%2F1tfpt5t4" rel="noreferrer"
          >2 route de Secenans, 70400 Granges-le-Bourg</a>
        </p>
        <div className="useful-information-map-container">
          <img src={map} className="useful-information-map" alt="map"/>
          {/* eslint-disable-next-line */}
          <a
            className='useful-information-map-hint'
            target="_blank"
            href="https://www.google.com/maps/place/Le+Moulin+de+la+Mangue/@47.558968,6.5758671,17z/data=!3m1!4b1!4m9!3m8!1s0x479269f5c3bcf7f7:0x2a76f11ac0bf6545!5m2!4m1!1i2!8m2!3d47.558968!4d6.578442!16s%2Fg%2F1tfpt5t4?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D"
          />
        </div>

        <h2>Tenue souhaitée</h2>
        <img src={ornement} className="useful-information-ornement" alt="logo"/>
        <p>
          Pour célébrer cette journée, nous vous invitons à adopter une tenue <strong>chic et élégante</strong>.
          Mesdames, nous vous remercions de réserver le blanc et les couleurs <strong>très</strong> claires à la mariée.
          Nous aimerions éviter les tenues décontractées et les couleurs flashy comme le jaune, l'orange, le fuschia et autres teintes fluo.
          L'essentiel est que vous soyez élégant et à l'aise pour profiter pleinement de cette journée à nos côtés.
        </p>

        <h2>Contacts</h2>
        <img src={ornement} className="useful-information-ornement" alt="logo"/>
        <p><strong>Thibaut Cornolti</strong> et <strong>Florine Claire Daures</strong>
        <br/>Adresse postale : 13 rue du Cheval, 67100 Strasbourg
        <br/>Adresse email : <a href="mailto:thifloc2025@gmail.com">thifloc2025@gmail.com</a></p>

        <p>Nos témoins : <strong>Maud, Luc, Manon</strong> et <strong>Marin</strong>.
        <br/>Adresse email : <a href="mailto:surprise.thifloc@gmail.com">surprise.thifloc@gmail.com</a>
        </p>

        <Dialog
          open={isEasterEggHostingOpened}
          onClose={() => setIsEasterEggHostingOpened(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <img src="https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExcnZ2ZG8xMTZibmFrMXRxd3ZjeDk4NHV6ZmN3Y2s5Z2JmOXM3ejNqMyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/o9W9ILrxLrHpe/giphy.webp" alt='gif' />
        </Dialog>
      </div>
  );
}

export default UsefulInformation;
