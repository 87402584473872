import './Menu.css';
import {Button} from "@mui/material";

export const menuItems = [
    "Le programme",
    "Confirmer sa venue",
    "Hébergements et accès",
    "Informations utiles",
    "À propos de nous",
    "À vos platines !",
    "Nous faire plaisir",
];

function Menu({setIsOpened}) {
  return (
      <div className="menu">
          {menuItems.map((item, index) => (
                <Button key={index} variant="contained" className="menu-item" onClick={() => setIsOpened(item)}>
                    {item}
                </Button>
          ))}
      </div>
  );
}

export default Menu;
