import './Accomodation.css';
import ornement from '../ornement.svg';
import { Dialog } from '@mui/material';
import { useState } from 'react';

function UsefulInformation({ isOpened, setIsOpened }) {
  const [isEasterEggHostingOpened, setIsEasterEggHostingOpened] = useState(false);

  return (
      <div className="accomodation-content">
        <h2 onClick={() => setIsEasterEggHostingOpened(true)}>Hébergements</h2>
        <img src={ornement} className="accomodation-ornement" alt="logo"/>
        <p>
          Nous avons réparti les <strong>45 couchages</strong> disponibles sur place.
          Si vous en faites partie, vous trouverez l’information dans votre formulaire <span className="accomodation-confirm">« Confirmez sa venue »</span>.
          <br/><br/>
          Pour ceux ne bénéficiant pas d'un couchage, voici <strong>deux hôtels</strong> situés à Villersexel, à 15 minutes du domaine, et qui offrent de nombreuses chambres. Pratique pour le covoiturage entre invités !
          <br/><br/>
          <strong><a href="https://laterrasse-villersexel.com" target="_blank" rel="noreferrer">Hôtel L’Auberge de la Terrasse</a></strong> :
          <br/>Un hôtel familial au charme authentique, où chaque chambre a été rénovée avec soin.
          <br/><strong><a href="https://lerelaisdesmoines.fr" target="_blank" rel="noreferrer">Hôtel Le Relais des Moines</a></strong> :
          <br/>Un ancien relais de diligence transformé en hôtel chaleureux avec une décoration soignée.
          <br/><br/>
          Et pour les plus aventuriers, il est aussi possible de <strong>camper</strong> sur place ou de <strong>stationner</strong> votre van aménagé sur le parking du domaine.
          <br/><br/>
          N’hésitez pas à nous <strong><a href="mailto:thifloc2025@gmail.com">contacter</a></strong> si besoin !
        </p>

        <h2>Accès</h2>
        <img src={ornement} className="accomodation-ornement" alt="logo"/>
        <p>
          <strong>Adresse du lieu de réception :</strong>
          <br/>Moulin de la Mangue
          <br/>
          <a
              target="_blank"
              href="https://www.google.com/maps/place/Le+Moulin+de+la+Mangue/@47.558968,6.5758617,1111m/data=!3m1!1e3!4m10!3m9!1s0x479269f5c3bcf7f7:0x2a76f11ac0bf6545!5m3!1s2024-12-30!4m1!1i2!8m2!3d47.558968!4d6.578442!16s%2Fg%2F1tfpt5t4" rel="noreferrer"
          >2 route de Secenans, 70400 Granges-le-Bourg</a>
          <br/>Deux parkings sont disponibles sur le domaine et seront indiqués à votre arrivée.
          <br/><br/>
          <strong>Gares les plus proches :</strong>
          <br/>Gare de Belfort : 26 km
          <br/>Montbéliard TGV : 28 km
          <br/><br/>
          <strong>Aéroports les plus proches :</strong>
          <br/>Aéroport EuroAirport Basel Mulhouse Freiburg : 95 km
          <br/>Aéroport Dole Jura : 134 km
        </p>

        <Dialog
          open={isEasterEggHostingOpened}
          onClose={() => setIsEasterEggHostingOpened(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <img src="https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExcnZ2ZG8xMTZibmFrMXRxd3ZjeDk4NHV6ZmN3Y2s5Z2JmOXM3ejNqMyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/o9W9ILrxLrHpe/giphy.webp" alt='gif' />
        </Dialog>
      </div>
  );
}

export default UsefulInformation;
