import './DDay.css';
import broglie from './broglie.svg';
import truck from './truck.svg';
import brunch from './brunch.svg';
import rings from './rings.svg';
import drinks from './drinks.svg';
import cake from './cake.svg';
import dance from './dance.svg';

export default function Us({ isOpened, setIsOpened }) {

  return (
      <div className="dday-content">
          <div className="dday-date">
              Mardi 29 Juillet 2025
          </div>
          <div className="dday-item">
              <div className="dday-item-image broglie">
                  <img src={broglie} alt="broglie"/>
                  <span>11h</span>
              </div>
              <span className="dday-item-title">Cérémonie civile</span>
              <span className="dday-item-subtitle">
                  <a
                      target="_blank"
                      href="https://www.google.com/maps/search/48.584875,+7.750501?coh=219680&utm_campaign=tt-rcs&entry=tts&g_ep=EgoyMDI1MDEyMi4wIPu8ASoASAFQAw%3D%3D" rel="noreferrer"
                  >
                      Hôtel de Ville de Strasbourg
                      <br/>Place Broglie
                  </a>
              </span>
          </div>
          <div className="dday-item">
              <div className="dday-item-image truck">
                  <img src={truck} alt="truck"/>
                  <span>19h</span>
              </div>
              <span className="dday-item-title">Soirée tartes flambées</span>
              <span className="dday-item-subtitle">
                  <a
                      target="_blank"
                      href="https://www.google.com/maps/place/Le+Moulin+de+la+Mangue/@47.558968,6.5758617,1111m/data=!3m1!1e3!4m10!3m9!1s0x479269f5c3bcf7f7:0x2a76f11ac0bf6545!5m3!1s2024-12-30!4m1!1i2!8m2!3d47.558968!4d6.578442!16s%2Fg%2F1tfpt5t4" rel="noreferrer"
                  >
                      Moulin de la Mangue
                  </a>
              </span>
          </div>
          <br/>
          <br/>
          <div className="dday-date">
              Mercredi 30 Juillet 2025
          </div>
          <div className="dday-item">
              <div className="dday-item-image rings">
                  <img src={rings} alt="rings"/>
                  <span>15h</span>
              </div>
              <span className="dday-item-title">Cérémonie laïque</span>
              <span className="dday-item-subtitle">
                  <a
                      target="_blank"
                      href="https://www.google.com/maps/place/Le+Moulin+de+la+Mangue/@47.558968,6.5758617,1111m/data=!3m1!1e3!4m10!3m9!1s0x479269f5c3bcf7f7:0x2a76f11ac0bf6545!5m3!1s2024-12-30!4m1!1i2!8m2!3d47.558968!4d6.578442!16s%2Fg%2F1tfpt5t4" rel="noreferrer"
                  >
                      Moulin de la Mangue
                  </a>
              </span>
          </div>
          <div className="dday-item">
              <div className="dday-item-image drinks">
                  <img src={drinks} alt="drinks"/>
                  <span>16h30</span>
              </div>
              <span className="dday-item-title">Cocktail</span>
          </div>
          <div className="dday-item">
              <div className="dday-item-image cake">
                  <img src={cake} alt="cake"/>
                  <span>20h</span>
              </div>
              <span className="dday-item-title">Dîner</span>
          </div>
          <div className="dday-item">
              <div className="dday-item-image">
                  <img src={dance} alt="dance"/>
              </div>
              <span className="dday-item-title">Soirée dansante</span>
          </div>
          <br/>
          <br/>
          <div className="dday-date">
              Jeudi 31 Juillet 2025
          </div>
          <div className="dday-item">
              <div className="dday-item-image brunch">
                  <img src={brunch} alt="brunch"/>
                  <span>10h</span>
              </div>
              <span className="dday-item-title">Brunch</span>
          </div>
      </div>
  );
}

